<!-- SideNav: The pop-in side navigation menu -->
<template>
<div class="flex flex-col fixed">
	<div :class="`${expanded ? 'w-52' : 'w-18'} flex flex-col justify-end mn3-navbar py-5 rounded-r text-left`">
        <div v-if="expanded" class="mn3-navbar-logo-wrapper rounded-sm m-2 p-2">
        <img :src="$store.state.theme.headerLogoUrl"
            class="w-full h-auto mx-1 rounded-sm" />
        </div>
        <!--Standard Menu-->
        <div v-if="!viewAllCustom" :style="navStyle" class="overflow-auto">
            <div v-for="link in links" :key="link.to" 
                @click="optionClicked(link.to)"
                style="direction: ltr"
                :title="link.label"
                :class="`${link.theme != undefined ? 'mn3-navbar-option-' + link.theme : 'mn3-navbar-option'}
                    flex flex-row justify-between items-center font-bold pl-3 hover:relative hover:bottom-1 cursor-pointer h-12 transition duration-150 ease-in-out leading-4`">
                <Icon :id="link.icon" class="h-8 w-8" />
                <p v-if="expanded" class="ml-2 flex-grow">{{link.label}}</p>
                <div v-if="link.notice > 0 && expanded"
                    class="rounded-full mn3-navbar-active h-6 w-6 py-1 text-center mr-2">{{link.notice}}</div>
                <div v-else-if="link.notice > 0 && !expanded"
                    class="rounded-full mn3-navbar-active h-4 w-4 text-center text-xs mr-1">{{link.notice}}</div>
                <div v-else class="mr-2"></div>
            </div>            
            <div v-for="link in customLinks" :key="link.to" 
                @click="optionClicked(link.to)"
                style="direction: ltr"
                :title="link.label"
                :class="`${link.label == undefined ? 'hidden' : 'flex'} mn3-navbar-option-alt flex-row justify-between items-center font-bold pl-3 hover:relative hover:bottom-1 cursor-pointer h-16 transition duration-150 ease-in-out leading-4`">
                <Icon :id="link.icon" class="h-8 w-8" />
                <p v-if="expanded" class="ml-2 flex-grow">{{link.label}}</p>
                <div v-if="link.notice > 0 && expanded"
                    class="rounded-full mn3-navbar-active h-6 w-6 py-1 text-center mr-2">{{link.notice}}</div>
                <div v-else-if="link.notice > 0 && !expanded"
                    class="rounded-full mn3-navbar-active h-4 w-4 text-center text-xs mr-1">{{link.notice}}</div>
                <div v-else class="mr-2"></div>
            </div>
        </div>
        <!--Custom links-->
        <div v-if="viewAllCustom" :style="navStyle" class="overflow-auto">
            <div v-for="link in customLinks" :key="link.to" 
                @click="optionClicked(link.to)"
                style="direction: ltr"
                :title="link.label"
                :class="`${link.theme != undefined ? 'mn3-navbar-option-' + link.theme : 'mn3-navbar-option'} flex flex-row justify-between items-center font-bold pl-3 hover:relative hover:bottom-1 cursor-pointer h-16 transition duration-150 ease-in-out leading-4`">
                <Icon :id="link.icon" class="h-8 w-8" />
                <p v-if="expanded" class="ml-2 flex-grow">{{link.label}}</p>
                <div v-if="link.notice > 0 && expanded"
                    class="rounded-full mn3-navbar-active h-6 w-6 py-1 text-center mr-2">{{link.notice}}</div>
                <div v-else-if="link.notice > 0 && !expanded"
                    class="rounded-full mn3-navbar-active h-4 w-4 text-center text-xs mr-1">{{link.notice}}</div>
                <div v-else class="mr-2"></div>
            </div>
        </div>
        <!--View all links button
        <div 
            @click="viewAllCustom = !viewAllCustom"
            title="View all Custom Links"
            class="hidden mn3-navbar-option flex flex-row justify-between items-center font-bold pl-3 hover:relative hover:bottom-1 cursor-pointer h-16 transition duration-150 ease-in-out leading-4">
            <Icon id="eye" class="h-8 w-8" />
            <p v-if="expanded" class="ml-2 flex-grow">{{(viewAllCustom ? 'View Nav' : 'View Custom')}}</p>
            <div v-else class="mr-2"></div>
        </div>-->
        <!--Logout link-->
        <div @click.prevent="$authapi.logout"
            title="Logout"
            :class="`${expanded ? 'pl-3 ' : 'pl-0'} flex flex-row justify-between items-center 
                font-bold hover:relative hover:bottom-1 cursor-pointer h-16 transition duration-150 ease-in-out leading-4`">
            <div :class="`${expanded ? 'm-2 rounded-sm' : 'm-0'} mn3-alert w-min mx-auto flex items-center px-3 py-1`">
                <Icon id="logout" class="h-8 w-8" />
                <p v-if="expanded" class="ml-2 flex-grow">Logout</p>
                <div class="mr-2"></div>
            </div>
        </div>
	</div>
    <!--Collapse/expand button-->
    <div @click="toggleCollapse()" 
        :title="`${expanded ? 'Collapse Menu' : 'Expand Menu'}`"
        class="cursor-pointer opacity-50 hover:opacity-90 text-center pl-4 mt-4">
        <Icon :id="`${expanded ? 'chevron-double-left' : 'chevron-double-right'}`" class="h-6 w-6" />
    </div>
</div>
</template>
<script>
	import { ref } from "vue";
	
	import Icon from '@/components/Icon.vue'
	import Menu from '@/components/Menu.vue'
	import MenuOption from '@/components/MenuOption.vue'
	
	export default {
	  components: {
	      Icon,
	      Menu,
	      MenuOption
	  },
	  props: {
        links: {							        //links: Links displayed in the sidenav
            type: Object,
            default: []
        },
        customLinks: {
            type: Object,
            default: []
        }
	  },
      computed: {
        navStyle(){
            this.navHeightUpdate++;
            return `height: ${window.innerHeight-325}px; direction: rtl`;  
        }
      },
	  created(){        
            window.addEventListener("resize", this.windowResize);
	  },
      methods:{           
        //optionClicked: Sidenav option was clicked
        // linkTo: Router link to follow
        optionClicked(linkTo){
                //Open an external link
                if(linkTo.indexOf('http') != -1){
                    window.open(linkTo, '_blank');
                }
                //Go to a screen
                else{
                    this.$router.push(linkTo);
                    window.scrollTo(0,0);
                }
        },
        toggleCollapse(){
            this.expanded = !this.expanded;
            this.emit('expanded', this.expanded)
        },               
        windowResize(e){
            this.navHeightUpdate++;
        }
      },
	  setup(props, { emit }){
	
            const expanded = ref(true);
            const viewAllCustom = ref(false)
            const navHeightUpdate = ref(0)
	
	    return {
			emit,
            expanded,
            navHeightUpdate,
            viewAllCustom
	    }
	  }
	}
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 2px;
  opacity: 0.5;
}
</style>