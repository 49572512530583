<!-- Chat -->
<template>
    <div class="w-full flex flex-col self-stretch">
    <div class="flex p-1 mn3-content justify-between items-center mb-2">
        <p class="font-bold text-lg uppercase px-2 leading-4">{{startingNewChat ? 'Starting a New Conversation' : 'Secure Messaging with ' + withName}}</p>
        <div class="flex-grow" />
        <div v-if="mode == 'fapi' && !startingNewChat" 
            @click="archiveCheck = true" 
            class="mn3-button-min rounded-sm py-1 px-2 cursor-pointer mr-1 flex flex-row items-center opacity-80 hover:opacity-100 duration-100"><Icon id="archive" class="h-4 w-4 mr-1" />Archive Conversation</div>
        <div class="mn3-cancel rounded-sm py-1 px-2 cursor-pointer" 
            title="Close Chat"
            @click="close()">X</div>
    </div>    
    <div v-if="!startingNewChat && !archiveCheck" class="w-full flex flex-row mn3-content px-5">
        <div class="w-full py-1 px-4 flex flex-row  items-center justify-between rounded-full mn3-content-alt">
            <div v-if="displayAttachments" class="flex flex-row items-center">
                <Icon id="attach" class="h-5 w-5" />
                <div>Conversation Attachments</div>
            </div>
            <label v-else class="flex flex-row items-center flex-grow">
                <Icon id="search" class="h-5 w-5 mr-2" />
                <input type="text" class="mn3-content-alt flex-grow" placeholder="Search Conversation" v-model="searchFor"
                    @keydown.esc="searchFor = ''" />
            </label>
            <div @click="toggleAttachments()" class="hidden cursor-pointer opacity-80 hover:opacity-100 flex flex-row items-center border-l pl-2">
                <div v-if="!displayAttachments">{{files.length}} Attachments</div>
                <div v-else>Close Attachments</div>                
                <Icon v-if="!displayAttachments" id="attach" class="h-5 w-5" />                
                <Icon v-else id="x" class="h-5 w-5" />
            </div>
        </div>
    </div>
    <div v-if="startingNewChat && !archiveCheck" class="w-full p-4">
        <!--Member Lookup if fapi-->
        <div v-if="mode == 'fapi' && lookedUpMember == undefined">       
            <div v-if="lookupError" class="mn3-alert rounded-sm p-2 w-full text-lg font-bold text-center flex flex-row items-center justify-center">
                <Icon id="exclamation" class="h-8 w-8 mr-2" />
                <span>Member Not Found</span>
            </div>     
            <Field 
                label="Member Account Number" 
                type="text" 
                :maxLength="24"
                textFormat="number"
                @enter="lookupMember()"
                :formValue="fv.acctNum"/>   
            <Button @click="lookupMember()" class="w-full" label="Lookup Member" type="submit" />
        </div>
        <div v-if="mode == 'fapi' && lookedUpMember != undefined"
            class="px-2 flex flex-row justify-between">
            <div class="flex flex-row">
                <div class="flex flex-col">
                    <span class="font-bold text-lg">{{lookedUpMember.name}} ({{lookedUpMember.id}})</span>
                    <span>{{lookedUpMember.email}}</span>
                </div>
            </div>
            <Button @click="lookedUpMember = undefined" icon="user" 
                class="mn3-cancel cursor-pointer rounded-sm p-1 text-sm" label="Lookup Different Member" />
        </div>
        <Field
            v-if="mode != 'fapi' || lookedUpMember != undefined"
            label="Conversation Title (Optional)"
            type="text"
            @enter="refMsg.focus()"
            :formValue="fv.title" />
    </div>    
    <div v-else-if="displayAttachments"
        class="flex flex-col mx-10">
        <div v-for="file in files" :key="file"
            class="mn3-content-hover cursor-pointer flex flex-row py-2 px-2 rounded">
            <Icon id="attach" class="h06 w-6" />
            <span>{{file}}</span>
            
        </div>
    </div>
    <div v-else-if="archiveCheck"
        class="flex flex-col m-5 rounded-sm mn3-alert m-2 p-2 items-center">
        <span class="font-bold text-lg">Are you sure you want to archive this conversation?</span>
        <div class="flex flex-row items-center">
            <Button @click="archiveConversation()" class="mr-2" icon="archive" label="Yes, Archive Conversation" />
            <Button @click="archiveCheck = false" icon="x" label="No, Do Not Archive" />
        </div>
    </div>
    <div v-else class="pb-28 md:pb-0 pl-2 overflow-x-auto md:overflow-y-scroll self-stretch"
            :style="chatHeight" id="msgBox" ref="msgBox">    
        <div :class="`${msgOuterClass(msg)} w-full flex`" v-for="msg in messagesToShow" :key="msg.key">
            <div :class="`${msgInnerClass(msg)} max-w-2xl py-2 px-4 m-1 rounded`">
                <div class="flex text-xs">
                    <p :class="`${msgFromClass(msg)} whitespace-nowrap font-bold`" class="mr-2">
                        {{msgDisplayName(msg)}}
                    </p>
                    <p :class="msgTimeClass(msg)">{{dateDisplay(msg.attributes.created)}}</p>
                </div>
                <p>{{msg.attributes.body}}</p>
            </div>
        </div>
        <div v-if="sendingMessage" :class="`justify-end w-full flex`">
            <div :class="`bg-blue-100 max-w-2xl py-2 px-4 m-1 rounded italic opacity-70`">
                <p>Sending Message</p>
            </div>
        </div>
    </div>
    <div v-if="!displayAttachments && !archiveCheck && (mode != 'fapi' || !startingNewChat || lookedUpMember != undefined)" class="w-full flex-col items-end text-right border-t pt-2 fixed md:relative bottom-16 px-4 md:bottom-0 bg-gray-50">
        <input type="text" class="w-full p-2" 
            v-model="message"
            ref="refMsg"
            @keypress.enter="send()"
            :placeholder="startingNewChat ? 'Type opening message' : 'Type a new message'" />
        <div v-if="error != ''" class="w-full mn3-alert rounded-b text-sm uppercase text-center p-1">{{ error }}</div>
        <div class="flex justify-between p-2">
            <!--
            <Icon class="w-6 h-6 mr-2 cursor-pointer" id="attach" />
            <Icon class="w-6 h-6 cursor-pointer" id="input" />-->
            <div class="flex-grow" />
            <div class="w-8 h-8" @click="send()">
                <Icon title="Send Message" id="send" class="cursor-pointer" />
            </div>
        </div>                
    </div>
</div>
</template>

<script>

    import { ref, computed } from 'vue'

    import Button from '@/components/Button.vue'
    import Field from '@/components/Field.vue'
    import Icon from '@/components/Icon.vue'
    import InputText from '@/components/InputText.vue'

export default{
    props:{  
        conversation: {
            type: Object,
            default: []
        },
        withName: String,
        thisName: String,
        startingNewChat:{
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'mapi'
        }
    },
    components:{
        Button,
        Field,
        Icon,
        InputText
    },
    created(){
        this.chatHeightUpdate++;
        window.addEventListener("resize", this.windowResize);

        this.loadConversationMessages();
    },
    mounted(){
    this.refMsg.focus();
    },
    computed: {
        messagesToShow(){

            if(this.searchFor == ''){
                return this.messages;
            }

            let messages = [];
            for(var key in this.messages){              
                if(this.messages[key].attributes.body.toLowerCase().indexOf(this.searchFor.toLowerCase()) != -1)
                    messages.push(this.messages[key]);
            }
            return messages;
        }
    },
    methods:{  
        async archiveConversation(){
            try{
                let resp = await this.$fapi.patch(this.conversation.links.self, {
                    "data": {
                        "attributes": {
                        "status": "closed", 
                        }
                    }
                });
                this.archiveCheck = false;
                this.close();
                this.$emit('refreshConversations');

            }catch(resp){
                console.log('resp catch: ', resp);
            }
        },
        //If attachments or search open close those, otherwise emit close to close convo
        close(){
            if(this.displayAttachments){
                this.displayAttachments = false;
            } else if(this.searchFor != ''){
                this.searchFor = '';}
            else{
                this.$emit('close')
            }

        },
        dateDisplay(raw){            
            return new Date(raw + 'Z').toLocaleDateString('en-us', {hour: 'numeric', minute: 'numeric', hour12: true, month: 'short', day:'numeric', timeZoneName: 'short'});
        },   
        async lookupMember(){
            console.log('this.fv.acctNum.value: ', this.fv.acctNum.value);
            try{
                let resp = await this.$fapi.get("account?id=" + this.fv.acctNum.value)
                
                console.log('lookupMember.fetched: ', resp);
                this.lookedUpMember = { name: resp.data.data.attributes.name, email: resp.data.data.attributes.email, id: this.fv.acctNum.value }
                this.fv.acctNum.value = '';
                this.lookupError = false;

                //Focus input on the message field, slight delay so input isn't null
                await new Promise(resolve => setTimeout(resolve, 5));  
                this.refMsg.focus();

            }catch(resp){
                console.log('resp catch: ', resp);
                this.lookupError = true
            }
            
        }, 
        msgDisplayName(msg){
            if(this.mode == 'fapi' && this.isFromThisUser(msg))
                return msg.attributes.userTitle;

            return this.isFromThisUser(msg) ? this.thisName : this.withName;
        },
        /* Return if msg is from user or other party */
        isFromThisUser(msg){            
            let fromMember = msg['relationships']['user']['data']['type'] == "FIMember";
            return (fromMember && this.mode == 'mapi') || (!fromMember && this.mode == 'fapi');
        },
        isFromOtherUser(memberType){            
            let fromMember = (memberType == "FIMember");
            return (fromMember && this.mode == 'mapi') || (!fromMember && this.mode == 'fapi');
        },
        async loadConversationMessages(){
            this.$store.state.hideBusyAnim = true;
            if(this.conversation['relationships'] == undefined) return;

            let fetched = [];
            if(this.mode == 'fapi'){                
                fetched = await this.$fapi.get(this.conversation["relationships"]["events"]["links"]["related"])
            }else if(this.mode == 'mapi'){                    
                fetched = await this.$mapi.get(this.conversation["relationships"]["events"]["links"]["related"])
            }
            let msgs = fetched.data.data;
            for(var ii = 0; ii < msgs.length; ii++){
                this.messages[msgs[ii].id] = msgs[ii];
            }

            //Check if additional messages to load
            if(fetched.data.links != undefined){
                let next = fetched.data.links.next;
                let loop = 0;
                if(next != undefined){
                    do{
                        fetched = [];
                        if(this.mode == 'fapi'){                
                            fetched = await this.$fapi.get(next)
                        }else if(this.mode == 'mapi'){                    
                            fetched = await this.$mapi.get(next)
                        }
                        msgs = fetched.data.data;
                        for(var ii = 0; ii < msgs.length; ii++){
                            this.messages[msgs[ii].id] = msgs[ii];
                        }
                        if(fetched.data.links == undefined || fetched.data.links.next == undefined || fetched.data.links.next == next)
                            next = undefined;
                        else
                            next = fetched.data.links.next;

                        //Loop escape
                        loop++;
                        if(loop >= 50){
                            next = undefined;
                        }
                    }while(next != undefined);
                }
            }
            
            this.$store.state.hideBusyAnim = false;
        },
        msgOuterClass(msg){ 
            let classes = ' ';

            if(!this.isFromThisUser(msg))
                classes += 'justify-start ';
            else
                classes += 'justify-end ';

            if(this.prevMsg !== undefined)
                if(this.isFromThisUser(msg) != this.prevMsg.fromSelf){
                    classes += 'mt-5 '
                }

            return classes;
        },
        msgInnerClass(msg){
            let classes = ' ';

            if(!this.isFromThisUser(msg))
                classes += 'bg-white ';
            else
                classes += 'bg-blue-100 ';

            return classes;
        },
        msgFromClass(msg){
            let classes = ' ';

            if(this.prevMsg !== undefined)
            if((this.isFromThisUser(msg) === this.prevMsg.fromSelf && msg.attributes.created === this.prevMsg.time))
                classes += 'hidden ' 

            return classes;
        },
        // Called last, so updates this.prevMsg to msg
        msgTimeClass(msg){
            let classes = ' ';

            if(this.prevMsg !== undefined)
                if(msg.time === this.prevMsg.time && this.isFromThisUser(msg) === this.prevMsg.fromSelf)
                    classes += 'hidden '

            if(this.isFromThisUser(msg))
                classes += 'flex w-full justify-end ';
            /*
            this.prevMsg = {
                fromSelf: this.isFromThisUser(msg),
                time: msg.attributes.created
            }*/

            return classes;
        },
        // Called last, so updates this.prevMsg to msg
        msgTimeClass_x(msgTime, memberType){
            let classes = ' ';
            console.log('msgTimeClass: ' + msgTime, memberType);

            if(this.prevMsg !== undefined)
                if(msgTime === this.prevMsg.time && this.isFromOtherUser(memberType) === this.prevMsg.fromSelf)
                    classes += 'hidden '

            if(this.isFromOtherUser(memberType))
                classes += 'flex w-full justify-end ';

            /*
            this.prevMsg = {
                fromSelf: this.isFromOtherUser(memberType),
                time: msgTime
            }*/

            return classes;
        },
        async send(){
            console.log('send');
            let issueFound = false;
            this.error = '';

            if(this.message.length == 0){
                issueFound = true;
                this.error = 'Type your message above'
            }

            if(!issueFound){
                if(this.startingNewChat){
                    console.log('__POST START CONVERSATION');

                    let data =  { subject: this.fv.title.value, body: this.message }
                    if(this.mode == 'fapi')
                        data['account'] = this.lookedUpMember.id

                    this.$emit('close')                    
                    this.$emit('postNewConversation', data);
                    //Set chatID to returned chat id
                    
                    this.message = '';
                    this.fv.title.value = '';
                }
                else{
                    console.log('__POST SEND MESSAGE');
                    this.$emit('postMessage', { body: this.message });
                    this.message = '';
                }
            }
        },
        //Toggle displaying attachments
        toggleAttachments(){
            this.displayAttachments = !this.displayAttachments;

        },
        windowResize(e){
            this.chatHeightUpdate++;
        }
    },
    setup( props, {  } ){
        
        const refMsg = ref(null);
        const sendingMessage = ref(false);
        const message = ref('')
        const error = ref('');
        const fv = {
            acctNum: ref(''),
            title: ref('')
        }
        const archiveCheck = ref(false);
        const lookedUpMember = ref()
        const searchFor = ref('');
        const displayAttachments = ref(false);
        const lookupError = ref(false)
        const msgBox = ref(null);
        const prevMsg = ref(undefined);
        const chatHeightUpdate = ref(0)
        const chatHeight = computed({          
                get: () => {
                    chatHeightUpdate.value++;   
                    if(window.innerWidth >= 768)                 
                        return `max-height: ${window.innerHeight-350}px;`;          
                    else 
                        return '';          
                }
            });

            function scrollToBottom(){            
                if(msgBox.value !== null){
                    msgBox.value.scroll(0,msgBox.value.offsetHeight)
                }
            }
        const files = ref([
            'screenshot.jpeg',
            'screenshot2.jpeg',
            'screenshot3.jpeg'
        ]);
        const messages = ref({});

        return {
            archiveCheck,
            chatHeight,
            chatHeightUpdate,
            displayAttachments,
            error,
            searchFor,
            files,
            lookupError,
            lookedUpMember,
            messages,
            msgBox,
            message,
            prevMsg,
            refMsg,
            scrollToBottom,
            sendingMessage,
            fv
        }
    }
}

</script>