<template>
        <div class="max-w-screen-lg w-full">
            <div v-if="error">{{error}}</div>
            <iframe id="frame" :src="alliedUrl" :style="frameHeight" class="w-full md:rounded" />
        </div>
</template>
<script>
    import { useRouter } from "vue-router"
    import { ref, computed } from 'vue'

    import Icon from '@/components/Icon.vue'

export default{
    components:{
        Icon
    },
    props:{
        mode: {
            type: String,
            default: 'allied'
        }
    },
    async created(){
        if(this.$props.mode == 'allied2')
            document.title= this.$store.state.fi.allied2Title;
        else
            document.title= this.$store.state.fi.alliedTitle;

        this.frameHeightUpdate++;
        window.addEventListener("resize", this.windowResize);

        //Check for exiting screen if not enabled
        if((!this.$store.state.fi.alliedEnabled && this.$props.mode == 'allied') ||
            (!this.$store.state.fi.allied2Enabled && this.$props.mode == 'allied2')){
            this.$router.push('/');
        }else{
            this.fetchAllied();
        }
    },
    methods: {
        async fetchAllied(){
            try{
                let url = "allied-sso";
                if(this.$props.mode == 'allied2')
                    url = "allied2-sso";

                var fetched = await this.$mapi.get(url)
            }catch(e){
                if(e.response && e.response.data && e.response.data.errors){
                  this.error = e.response.data.errors[0].detail;
                }else{
                  this.error = e;
                }
            }
            this.alliedSso = fetched;
            this.alliedUrl = fetched.data.data.attributes.url;     
        },
        windowResize(e){
            this.frameHeightUpdate++;
        }
    },
    setup(){
        const alliedSso = ref("");
        const alliedUrl = ref("");
        const error = ref("");

        const frameHeightUpdate = ref(0)
        const frameHeight = computed({          
            get: () => {
                frameHeightUpdate.value++;      
                    
                if(window.innerWidth >= 768)                 
                    return `height: ${window.innerHeight-225}px;`;      
                else 
                    return `height: ${window.innerHeight-132}px;`;           
            }
        });

        return {
            error,
            alliedSso,
            alliedUrl,
            frameHeight,
            frameHeightUpdate
        }
    }
}
</script>
