<template>
    <div class="w-full">
        <div class="w-full flex flex-wrap md:flex-wrap-0 justify-between items-center border-b md:border-0">
            <div class="w-full md:w-auto mb-5">
                <h2 class="text-xl flex justify-start items-top md:pb-0 pb-5 border-b">
                    <router-link to="/accounts" class="font-bold flex items-top pr-4"><Icon id="chev-left" class="h-5 w-5 mt-1" />Accounts</router-link> 
                    <p>/</p> <p class="pl-4" v-if="nickname != undefined">{{nickname}} <span class="text-xs pl-4 uppercase">({{accountDesc}})</span></p>
                             <p v-else class="pl-4">{{accountDesc}}</p>
                </h2>
                <div class="flex w-full justify-start md:justify-start text-sm pt-5 md:pt-1 px-2">
                    <p class="font-bold">{{accountType}}: {{accountId}}</p>
                    <p class="mx-3">•</p>
                    <div class="flex flex-col">
                        <p class="font-bold">Account Opened: {{formatDate(openDate)}}</p>
                        <p v-if="accountType == 'Investment'" class="font-bold">Account Matures: {{formatDate(matureDate)}}</p>
                    </div>
                </div>
            </div>
            <div v-if="showRtn" class="w-full px-1 md:px-0 md:w-64 whitespace-nowrap flex flex-col justify-start self-stretch items-end font-mono text-sm leading-5">
                <Button :icon="revealRtn ? 'eye-slash' : 'eye'" class="w-full self-stretch" padding="0" text="xs" :label="`${revealRtn ? 'Hide' : 'Show' } RTN/Account #`" @click="revealRtn = !revealRtn" />
                <transition name="fade">
                    <div v-if="revealRtn" class="w-full mn3-content-alt rounded-b-sm mx-auto justify-between items-end flex flex-col px-2">
                        <div class="w-2/3 md:w-full flex flex-col mx-auto">
                            <div class="py-1 md:py-0 flex flex-row w-full justify-between items-center"><Icon id="building-office" class="flex-shrink-0 h-4 w-4 mr-1" /><span class="flex-grow">Routing Number:</span> <span>{{$store.state.fi.rtn}}</span></div>
                            <div class="py-1 md:py-0 flex flex-row w-full justify-between items-center"><Icon id="identification" class="flex-shrink-0h-4 w-4 mr-1" /><span class="flex-grow">Account Number:</span> <span>{{accountData.accountNumber}}</span></div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="w-full mn3-content flex my-4 flex-col md:flex-row rounded border overflow-hidden">
            <!-- Investment / Certificate -->
            <div v-if="accountType == 'Investment'"  class="flex flex-col md:flex-row w-full">
                <div class="flex-grow p-4 flex flex-col text-right border-b md:border-b-0 md:border-r md:text-center">
                    <div class="uppercase text-sm font-bold tracking-tighter">Interest Rate</div>    
                    <div class="text-3xl font-bold my-1">{{accountIntRate}}%</div>
                </div>
                <div class="flex-grow p-4 flex flex-col text-right border-b md:border-b-0  md:border-r md:text-center">
                    <div class="uppercase text-sm font-bold tracking-tighter">Dividends YTD</div>    
                    <div class="text-3xl font-bold my-1">{{formatMoney(dividends)}}</div>
                </div>
                <div class="flex-grow p-4 border-b-0 md:border-b-0 md:border-r flex flex-col text-right md:text-center">
                    <div class="uppercase text-sm font-bold tracking-tighter">Current Balance</div>    
                    <div class="text-3xl font-bold my-1">{{formatMoney(curBal)}}</div>
                </div>
            </div>
            <!-- Other -->
            <div v-else class="flex flex-col md:flex-row w-full">
                <div v-if="accountType == 'Investment' || accountType == 'LineOfCredit' || accountType == 'Loan'" class="flex-grow p-4 flex flex-col text-right border-b md:border-b-0 md:border-r md:text-center">
                    <div class="uppercase text-sm font-bold tracking-tighter leading-4">Interest Rate</div>    
                    <div class="text-3xl font-bold my-1">{{accountIntRate}}%</div>
                </div>
                <div v-if="accountType != 'Loan'" class="flex-grow p-4 border-b md:border-b-0 md:border-r flex flex-col text-right md:text-center">
                    <div class="uppercase text-sm font-bold tracking-tighter leading-4">{{(accountType == 'LineOfCredit' ? 'Credit Limit' : 'Available Balance')}}</div>   
                    <div class="text-3xl font-bold my-1">{{formatMoney(availBal)}}</div>   
                </div>
                <div v-if="payoffBalance != undefined && payoffBalance > 0" :class="`flex-grow p-4 border-b md:border-b-0 md:border-r flex flex-col text-right md:text-center`">
                    <div class="uppercase text-sm font-bold tracking-tighter leading-4">Payoff Balance</div>    
                    <div :class="`text-3xl font-bold my-1`">{{formatMoney(payoffBalance)}}</div>
                </div>
                <div class="flex-grow p-4 border-b md:border-b-0 md:border-r flex flex-col text-right md:text-center">
                    <div class="uppercase text-sm font-bold tracking-tighter leading-4">Current Balance</div>    
                    <div class="text-3xl font-bold my-1">{{formatMoney(curBal)}}</div>
                </div>
                <div v-if="accountType == 'LineOfCredit' || accountType == 'Loan'" :class="`flex-grow p-4 md:border-r flex flex-col text-right md:text-center md:rounded-r ${(isDueDatePast && curBal > 0 ? 'mn3-alert' : '')}`">
                    <div class="uppercase text-sm tracking-tighter font-bold leading-4">Next Payment Due on <span class="font-bold">{{formatDate(nextPaymentDate)}}</span></div>    
                    <div :class="`text-3xl font-bold my-1`">{{formatMoney(nextPaymentAmount)}}</div>
                </div>
            </div>
        </div>
        <div> 
            <Table                
                id="acct-sum"
                title="Transactions"
                ref="account"
                @filterDateUpdated="filterDateUpdated"
                @togglePending="showPending = !showPending"
                @downloadCsv="recordsToCsv()"
                :recordKey="recordKey"
                table="account"
                :hasQuickFilter="true"
                :hasFullFilter="true"
                :hasDateFilter="true"
                :columns="accountColumns"
                :filterBy="accountFilters"
                :recordsLoading="loadingRecordsByDate || loadingAdditionalRecords"
                :records="records" 
                :recordsPending="recordsPending"
                :recordError="dateError"                
                :showCsv="true"
                :showRecordCount="true"
                :sortOrder="-1"
                sortCompare="numeric"
            >    
            <!--Pending transactions-->
            <div class="w-full flex flex-col mb-1">           
                <div v-if="recordsPending.length > 0" class="flex flex-col w-full mn3-highlight px-2 pt-1 rounded">
                    <div class="text-xs center font-bold uppercase text-center">Pending Transactions</div>
                    <div v-for="r in recordsPending" :key="r"
                        class="flex flex-row items-center justify-between py-1">
                        <div style="width: 120px" class="ml-2 flex-grow-0 flex-shrink-0"> {{formatDate(r.attributes.expirationDate)}}</div>
                        <div class="text-left flex-grow">{{r.attributes.description}}</div>
                        <div class="flex-grow-0 flex-shrink-0 text-right pr-1">{{formatMoney(r.attributes.amount)}}</div>
                        <div style="width: 110px" class="hidden md:block" />
                    </div>
                </div>
            </div>
            </Table>         
            <div 
                v-if="moreRecordsToLoad && !dateMode"
                @click="loadAdditionalRecords(false);"
                class="w-full p-2 text-center mn3-content-alt-hover cursor-pointer md:border-l md:border-r border-b rounded-b text-sm font-bold">
                <transition name="fade" mode="out-in">
                    <div v-if="lastRecordLoaded">All Records Loaded
                        <p v-if="showMoreRecordsDate"
                            class="text-xs">Searched for records as far back as {{formatDate(additionalRecordStart.toString().split('T')[0])}}</p></div>
                    <div v-else-if="!loadingAdditionalRecords">Scroll Down or Click to Load More Records
                        <p v-if="showMoreRecordsDate"
                            class="text-xs">Searched for records as far back as {{formatDate(additionalRecordStart.toString().split('T')[0])}}</p>
                    </div>
                    <div v-else class="flex justify-center items-center"><img src="@/assets/img/loading.gif" class="w-6 h-6" /><p>Loading Additional Records...</p></div>
                </transition>
                </div>    
        </div>
    </div>
</template>
<script>

    import { computed, ref } from "vue";

    //Import components
    import Button from '@/components/Button.vue'
    import Field from '@/components/Field.vue'
    import Form from '@/components/Form.vue'
    import Icon from '@/components/Icon.vue'
    import Menu from '@/components/Menu.vue'
    import MenuOption from '@/components/MenuOption.vue'
    import Table from '@/components/Table.vue'
    import TableRecords from "@/components/TableRecords.vue";
    
    import acctFillerData from '@/assets/scripts/AcctFillerData.js'
    import acctFillerRecords from '@/assets/scripts/AcctFillerRecords.js'

    export default {
        name: 'Home',
        components: {
            Button,
            Field,
            Form,
            Icon,
            Menu,
            MenuOption,
            Table,
            TableRecords
        },
        computed: {    
            /* If next payment due date has passed */
            isDueDatePast(){       
                if(this.nextPaymentDate == undefined)
                    return false;
                
                let split = this.nextPaymentDate.split('-')
                let date = new Date(parseInt(split[0]), parseInt(split[1])-1, parseInt(split[2]))
                let today = new Date(new Date().toDateString())

                return date < today;
            },
            showRtn(){
                return (this.accountData && this.accountData.accountNumber);
            }
        },
        methods: {
            /* Get date X days from date */
            addDays(date, days) {
                var result = new Date(date);
                result.setDate(result.getDate() + days);
                return result;
            },
            /* Update filter dates and re-render the table */
            filterDateUpdated(e){
                if(e.start == '' || e.end == ''){
                    this.dateStart = ''
                    this.dateEnd = ''    
                }else{                    
                    this.dateStart = e.start
                    this.dateEnd = e.end    
                }

                //Load if both dates valid
                if(this.formatDate(this.dateStart.split('T')[0]) != '' && this.formatDate(this.dateEnd.split('T')[0]) != ''){
                    this.records = [];
                    this.loadRecordsByDateRange();
                } 
                //Or if both dates are cleared load default records
                else if(e.start == '' && e.end == ''){
                    this.records = [];
                    this.additionalRecordStart = "";
                    this.dateError = "";
                    this.loadAdditionalRecords(true)
                }

				this.recordKey += 1; //R
            },
            //Return '01/31/24 19:07' from '2023-11-01T19:07:01+00:00'
            formatDateForCsv(raw){
                console.log('formatDateForCsv: ', raw);
                if(raw == undefined)
                    return "";

                let split = raw.split('T');
                let date = this.formatDate(split[0]);
                let timeSplit = split[1].split(':');
                let hour = parseInt(timeSplit[0]);
                let min = timeSplit[1];
                let amPm = 'AM';
                if(hour > 12){
                    amPm = 'PM';
                    hour -= 12;
                }
                return date + ' ' + hour + ':' + min + amPm;

            },
            /* Format date for display */
            formatDate(raw){
                if(raw == undefined)
                    return "";

                let split = raw.split('-');
                //Make sure full date
                if(split.length < 3 || split[0].length < 4)
                    return "";

                //Make sure valid year (No leading zero from date input)
                if(split[0][0] == 0)
                    return "";

                return split[1] + '/' + split[2] + '/' + split[0];
            },
            /* Format fetched records for display */
            formatHistoryData(raw){
            
                let index = 0;
                let records = [];

                for(var key in raw["data"]["data"]){
                    let rec = raw["data"]["data"][key]
                    index++;

                    let lines = rec["attributes"]["remark"].split("\n");
                    let descLine1 = rec["attributes"]["codeDescription"] + (lines[0] != undefined && lines[0].length > 0 ? ': ' + lines[0] : '');

                    records.push({
                        index: index,
                        id: rec["id"],
                        accountId: rec["attributes"]["accountId"],
                        amount: rec["attributes"]["amount"],
                        balance: rec["attributes"]["postedActualBalance"],
                        desc: descLine1,
                        lines: lines.slice(1),
                        date: rec["attributes"]["dateTimePosted"],
                        dateNum: Date.parse(rec["attributes"]["dateTimePosted"]),
                        type: rec["attributes"]["type"],
                        feeAmount:  rec["attributes"]["feeAmount"],
                        interestAmount:  rec["attributes"]["interestAmount"],
                        principalAmount:  rec["attributes"]["principalAmount"],
                        checkImagesUrl: rec["attributes"]["checkImagesUrl"],
                        checkNumber: rec["attributes"]["checkNumber"]
                    })

                    let recDate = new Date( rec["attributes"]["dateTimePosted"])
                    if(recDate < this.additionalRecordStart || this.additionalRecordStart == ""){
                        this.additionalRecordStart = recDate;
                    }
                    
                    if(recDate < this.dateOfOldestRecord || this.dateOfOldestRecord == ""){
                        this.dateOfOldestRecord = recDate;
                    }
                }

                let data = {
                    records: records
                };

                return data;
            },
            /* Format amount of money for display */
            formatMoney(raw){
                if(isNaN(parseFloat(raw)))
                    return '';
                return this.mon.format(raw);
            },
            /* Get number of days between two dates */
            getNumberOfDays(start, end) {
                const date1 = new Date(start);
                const date2 = new Date(end);

                // One day in milliseconds
                const oneDay = 1000 * 60 * 60 * 24;

                // Calculating the time difference between two dates
                const diffInTime = date2.getTime() - date1.getTime();

                // Calculating the no. of days between two dates
                const diffInDays = Math.round(diffInTime / oneDay);

                return diffInDays;
            },
            /* Load more records if scroll to bottom of screen */
            handleScroll (evt){

                    if(this.dateMode) return;

                    var st = window.pageYOffset || document.documentElement.scrollTop; 
                    // downward scroll
                    if (st > this.lastScroll){                        
                        let buffer = 5;
                        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + buffer >= document.documentElement.offsetHeight
                        if (bottomOfWindow && !this.loadingAdditionalRecords && !this.lastRecordLoaded) {
                            this.loadAdditionalRecords(false);
                        }
                    }
                    this.lastScroll = st <= 0 ? 0 : st;
            }, 
            /* Load more records if scroll to bottom of screen */ 
            handleWheel (evt){
                
                if(this.dateMode) return;

                let scrolledDown = evt.deltaY > 0;
                let windowHasScroll = document.body.scrollHeight > window.innerHeight;
                if(scrolledDown && !windowHasScroll && !this.loadingAdditionalRecords && !this.lastRecordLoaded){
                    this.loadAdditionalRecords(false);
                }
            },
            
            recordsToCsv(){
                console.log('recordsToCsv()');
                let keyAdded = false;
                let csv = [];


                let columns = [];
                if(this.accountType == 'Checking'){
                    columns = ['index', 'date', 'desc', 'type', 'checkNumber', 'amount', 'balance'];
                }else if(this.accountType == 'Savings'){
                    columns = ['index', 'date', 'desc', 'type', 'amount', 'balance'];
                }else{
                    columns = ['index', 'date', 'desc', 'type', 'feeAmount', 'interestAmount', 'principalAmount', 'amount', 'balance']
                }

                for (var key in this.records) {
                    let csvRow = [];
                    let record = this.records[key];

                    //Add key row if first record
                    if(!keyAdded){
                        for(var ii = 0; ii < columns.length; ii++){
                            csvRow.push(columns[ii]);
                        }
                        csv.push(csvRow.join(','));
                        csvRow = [];
                        keyAdded = true;
                    }

                    //Add record row
                    for(var ii = 0; ii < columns.length; ii++){

                        switch(columns[ii]){
                            default:
                                csvRow.push(record[columns[ii]]);
                                break;

                            case 'date':
                                csvRow.push(this.formatDateForCsv(record[columns[ii]]));
                                break;

                            case 'amount':
                            case 'balance':
                            case 'feeAmount':
                            case 'interestAmount':
                            case 'principalAmount':
                                if(record[columns[ii]] != undefined && record[columns[ii]] != '')
                                    csvRow.push('$' + record[columns[ii]]);
                                else
                                    csvRow.push(record[columns[ii]])
                                break;
                        }
                    }

                    csv.push(csvRow.join(','));
                }
                csv = csv.join('\n');
                this.downloadCsv(csv);
            },
            downloadCsv(csv){
                var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');
                let filename = this.accountId + '_' + this.accountDesc + "_" + utc + ".csv";
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, filename);
                } else {
                    var link = document.createElement("a");

                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", filename);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            },
            /* Load more account records */
            async loadAdditionalRecords(startOver){                
                this.dateMode = false;
                if(this.fetchLoopCheck > 12){
                    this.lastRecordLoaded = true;
                    this.loadingAdditionalRecords = false;
                    return;
                }
                this.loadingAdditionalRecords = true;

                await new Promise(resolve => setTimeout(resolve, 50));
                // Determine start/end dates for fetch
                let end = "";
                if(this.additionalRecordStart == "" || startOver){
                    end = this.addDays(new Date().toISOString(), 0).toISOString();
                }
                else{
                    end = new Date(this.additionalRecordStart).toISOString();
                }
                let st = this.addDays(end, -30).toISOString();
                this.additionalRecordStart = st;

                try{
                    const aid = this.$route.params.accountId;   
                    let param = '';

                    if(st !='' && end != ''){
                        //Strip time from dates
                        st = st.split('T')[0];
                        end = end.split('T')[0];
                        let today = new Date().toISOString().split("T")[0];
                        let endIsToday = end == today;
                        if(st !== undefined)
                            param += 'filter[start]=' + st
                        if(end !== undefined && !endIsToday)
                            param += '&filter[end]=' + end
                    }
                    
                    const historyData = await this.$mapi.get("accounts/" + aid + "/history?" + param); 

                    let formattedHistoryData = this.formatHistoryData(historyData)
                    //Records found, merge with existing
                    if(formattedHistoryData['records'].length > 0){    
                        this.fetchLoopCheck = 0;                    
                        this.mergeAdditionalRecords(formattedHistoryData['records'], end, st);
                        this.loadingAdditionalRecords = false
                        this.moreRecordsToLoad = true                
                    }
                    //Otherwise try an additional fetch
                    else{
                        this.fetchLoopCheck++;
                        this.loadAdditionalRecords(false);
                    }
                }
                catch(ex){
                    console.log('ex: ', ex);
                    if(ex.response == null){
                        console.log("Fetch Records Error: ", ex)
                    }else{
                        let errorDetail =  ex.response.data.errors[0].detail;
                        if(errorDetail != undefined){
                            errorDetail = errorDetail.split(':')[0];
                        }

                        //Check known error responses
                        if(errorDetail == "DateRangeTooWideError"){
                            console.log('DATE RANGE ERROR')
                            let errorDisplay = "Date Range Error: Range " + ex.response.data.errors[0].detail.split(" range ")[1];
                            console.log(errorDisplay)
                            this.dateError = errorDisplay;
                        }
                        console.log('_Account.filterDatesChanged.Reload Error Catch: ', ex.response.data.errors[0].detail)
                    }
                    
                    this.loadingAdditionalRecords = false
                }
            },    
            async loadRecordsByDateRange(){
                

                let st = this.dateStart,
                end = this.dateEnd;
                this.dateMode = true;

                //if(this.loadingRecordsByDate || this.loadingAdditionalRecords) return;
                this.records = [];

                this.loadingRecordsByDate = true
                try{
                    const aid = this.$route.params.accountId;   
                    let param = '';

                    if(st !='' && end != ''){
                        //Strip time from dates
                        st = st.split('T')[0];
                        end = end.split('T')[0];

                        //Check if end date is today
                        let today = new Date().toISOString().split("T")[0];
                        let endIsToday = end == today;

                        if(st !== undefined)
                            param += 'filter[start]=' + st
                        if(end !== undefined && !endIsToday)
                            param += '&filter[end]=' + end
                    }

                    const historyData = await this.$mapi.get("accounts/" + aid + "/history?" + param);
                    
                    let formattedHistoryData = this.formatHistoryData(historyData)        
                    this.records = formattedHistoryData.records
                    this.moreRecordsToLoad = true
                    this.recordKey += 1; //Re-render the table component
                    
                    this.dateError = "";
                    this.loadingRecordsByDate = false
                    
                    //If no records found in date range, display error
                    if(this.records.length == 0){
                        this.dateError = 'No records were found within the selected date range.'
                    }
                }
                catch(ex){
                    let errorDetail =  ex.response.data.errors[0].detail;
                    if(errorDetail != undefined){
                        errorDetail = errorDetail.split(':')[0];
                    }
                    this.loadingRecordsByDate = false
                    //Check known error responses
                    if(errorDetail == "DateRangeTooWideError" || errorDetail == "transactionPostedDateRange range must be less than or equal to 90 days"){
                        let errorDisplay = "Date Range Error: Range " + ex.response.data.errors[0].detail.split(" range ")[1];
                        this.dateError = errorDisplay;
                    }
                    //Existing error not found, pass from response
                    else{
                        this.dateError = errorDetail;
                    }
                    console.log('_Account.filterDatesChanged.Reload Error Catch: ', ex.response.data.errors[0].detail)
                }
            },
            /* Merge newly fetched records with those currently displayed */
            mergeAdditionalRecords(addtRecords, end, st){
                let startCount = this.records.length;
                let endDate = this.addDays(end, -1);


                //remove duplicates
                for(var ii = 0; ii < addtRecords.length; ii++){
                    let rec = addtRecords[ii];
                    let recDate = new Date(addtRecords[ii].date)

                    // If date is after starting date, add record
                    if(endDate > recDate){
                        this.records.push(rec);
                    }
                    // otherwise check this record against existing records to see if it should be added
                    else{
                        let isCopy = false;

                        for(var jj = 0; jj < this.records.length; jj++){

                            let recJ = this.records[jj];
                            
                            if(recJ['id'] == rec['id']){
                                isCopy = true;
                                jj = this.records.length;
                            }
                        }

                        if(!isCopy){
                            this.records.push(rec);
                        }
                        else{
                            this.skippedRecDebug.push(rec);
                        }
                    }       
                }

                //Update display for how far back records have been searched for
                this.additionalRecordStart = this.addDays(st, 1).toISOString();

                // if no records found in range, update where to check next from
                let endCount = this.records.length;
                if(startCount == endCount){               
                    this.showMoreRecordsDate = true; 

                    // check if 2 years since last record
                    let days = this.getNumberOfDays(this.additionalRecordStart, this.dateOfOldestRecord);
                    if(days >= 365 * 2){
                        console.log('2 year gap found');
                        this.lastRecordLoaded = true;
                    }
                }                

				this.recordKey += 1; //Re-render the table component
            },                   
            /* Set account variables with fetched data */
            setAccountData(data){    
                this.accountData = data                  
                this.accountId = data.displayAccountId
                this.accountType = data.type
                this.curBal = data.actualBalance
                this.availBal = data.availableBalance
                this.accountDesc = data.description
                this.openDate = data.openDate
                this.nextPaymentDate = data.nextPaymentDate
                this.nextPaymentAmount = data.nextPaymentAmount
                this.payoffBalance = data.payoffBalance
                this.accountIntRate = data.interestRate
                this.dividends = data.ytdInterestEarned
                this.matureDate = data.maturityDate
                this.nickname = data.nickname
            }
        },
        /* Mount listeners to load more record on scroll */
        mounted () {
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('wheel', this.handleWheel);
        },
        /* Remove listeners to load more record on scroll */
        unmounted(){
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('wheel', this.handleWheel);
        },
		async created(){
            document.title="Account Summary";
            
            // Load account data and records
            //this.loadingRecordsByDate = true
            this.loadAdditionalRecords(true);
            const aid = this.$route.params.accountId;            
            const accountData = await this.$mapi.get("accounts/" + aid);            
            this.setAccountData(accountData["data"]["data"]['attributes']);

            let fetchPending = await this.$mapi.get("accounts/"+aid+"/holds")
            this.recordsPending = fetchPending.data.data

		},
        setup(){

            /* --Variables-- */
            const accountDesc = ref("")             //accountDesc: Current description of the account
            const accountId = ref("")               //accountId: ID of the current account
            const accountIntRate = ref("")          //accountIntRate: Interest rate of account
            const accountQuickFilter = ref("")      //accountQuickFilter: Current QuickFilter value for accounts
            const accountType = ref("")             //accountType: Type of account
            const availBal = ref("")                //availBal: Available balance on account
            const curBal = ref("")                  //curBal: Current balance on account
            const dateEnd = ref("")                 //dateEnd: Cutoff date when filtering by date
            const dividends = ref(0);
            const dateStart = ref("")               //dateStart: Beginning date when filtering by date
            const dateError = ref("");
            const dateMode = ref(false);            //dateMode: If viewing within date range, disable loading on scroll/click
            const nickname = ref("");               //nickname: User's nickname for the account
            const fv = {}                           //fv: Array of refs used to store input values for screen
            fv["accountNickname"] = ref("")         //fv.accountNickname: Input to change nickname on account
            const matureDate = ref("00/00/00")      //matureDate: Maturation date of acct
            const mon =                             //mon: Used for money formatting
                new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'})
            const nextPaymentAmount = ref(0)        //nextPaymentAmount: Amount of next payment due
            const nextPaymentDate = ref("")         //nextPaymentDate: Date of next payment
            const payoffBalance = ref(0);
            const openDate = ref("")                //openDate: Opening date of account
            const recordKey = ref(1)                //recordKey: Update value to cause table records to re-render
            const records = ref([])                 //records: Account records
            const recordsPending = ref([])          //recordsPending: Transactions on hold
            const loadingRecordsByDate = ref(false)        //loadingRecordsByDate: If records are currently being loaded
            const showEditInfo = ref(false)         //showEditInfo: If form to edit account info is open
            const moreRecordsToLoad = ref(false)    //moreRecordsToLoad: If any additional records to load
            const loadingAdditionalRecords = ref(false)       //loadingAdditionalRecords: If currently loading additional records
            const additionalRecordStart = ref("")    //additionalRecordStart: Used when loading additional records
            const showPending = ref(false)          //showPending: Show pending transactions
            const skippedRecDebug = ref([]);        //skippedRecDebug: Debug used for merging records
            const lastRecordLoaded = ref(false);    //lastRecordLoaded: If no more records to load
            const showMoreRecordsDate = ref(false)  //showMoreRecordsDate: If loading records display should show furthest date fetched from
            const dateOfOldestRecord = ref("")      //dateOfOldestRecord: Used to check if 2 years since last rec
            const lastScroll = ref(0);              //lastScroll: Used to detect scroll direction
            const fetchLoopCheck = ref(0)
            const revealRtn = ref(false)
            const accountData = ref({})
            
            //Account Table Structure and Filters
            const accountColumns = ref({
                            date: {
                                header: 'Date',
                                headerClass: 'ml-2',
                                width: '160px'
                            },
                            desc: {
                                header: 'Description',
                                align: 'left'
                            }, 
                            checkNum: {
                                header: '',
                                align: 'left',
                                width: '115px'
                            },
                            amt: {
                                header: accountType.value == 'Loan' ? 'Payment' : 'Amount',
                                align: 'right',
                                width: '110px',
                            },
                            balance: {
                                header: 'Balance',
                                align: 'right',
                                headerClass: 'mr-2',
                                width: '110px',
                            }
                        });
            const accountFilters = ref([
                            {
                            label: 'Description',
                            key: 'desc',
                            classes: 'w-full'
                            },
                            {
                            label: accountType.value == 'Loan' ? 'Payment' : 'Amount',
                            key: 'amount',
                            classes: 'w-full md:w-1/2',
                            textFormat: 'amount'
                            },
                            {
                            label: 'Balance',
                            key: 'balance',
                            classes: 'w-full md:w-1/2',
                            textFormat: 'amount'
                            },
                        ])

        return{
          acctFillerData,
          acctFillerRecords,
          accountColumns,
          accountData,
          accountFilters,
          accountDesc,
          accountId,
          accountIntRate,
          accountQuickFilter,
          accountType,
          availBal,
          curBal,
          dateEnd,
          dateError,
          dateMode,
          dateOfOldestRecord,
          dateStart,
          dividends,
          fetchLoopCheck,
          fv,
          lastRecordLoaded,
          loadingAdditionalRecords,
          matureDate,
          mon,
          moreRecordsToLoad,
          nextPaymentDate,
          nextPaymentAmount,
          nickname,
          openDate,
          payoffBalance,
          records,
          revealRtn,
          loadingRecordsByDate,
          showEditInfo,
          lastScroll,
          additionalRecordStart,
          recordKey,
          recordsPending,
          showPending,
          skippedRecDebug,
          showMoreRecordsDate
        }    
      }
    }
</script>
