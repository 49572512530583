<template>
    <div v-if="error != ''"
    class="mx-auto mn3-alert p-2 my-2 rounded-sm flex flex-row items-center">
        <Icon id="exclamation" class="h-6 w-6 mr-2" />
        <span>{{error}}</span>        
    </div>
    <div class="max-w-screen-lg w-full mx-5 mt-24" v-if="openInBrowser">
        <div v-if="ipayUrl != ''" class="p-2 cursor-pointer mn3-button mn3-submit rounded-sm block flex" @click="openUrl">
            <Icon class="h-6 w-6 mr-2" id="export" />Please click here to open {{$store.state.fi.ipayTitle}}.
        </div>
    </div>
    <div class="max-w-screen-lg w-full" v-else>
        <iframe id="frame" :src="ipayUrl" :style="frameHeight" class="w-full md:rounded" />
    </div>
</template>
<script>
    import { ref, computed } from 'vue'

    import Icon from '@/components/Icon.vue'

export default{
    components:{
        Icon
    },
    async created(){
        document.title= this.$store.state.fi.ipayTitle;

        this.frameHeightUpdate++;
        window.addEventListener("resize", this.windowResize);
        let userAgent = navigator.userAgent + '';
        userAgent = userAgent.toLowerCase();

        // check if on ios or using a browser on a mac os device
        if(this.$store.state.isIos ||
            (!Capacitor.isNative && userAgent.indexOf('mac os') != -1)){
            this.openInBrowser = true
        }

         if(!this.$store.state.fi.ipayEnabled){
            this.$router.push('/');
        }else{
            this.fetchIpay();
        }
    },
    methods: {
        async fetchIpay(){

            try{
                let fetched = await this.$mapi.get("ipay-sso")            
                this.ipaySso = fetched;
                this.ipayUrl = fetched.data.data.attributes.url;     

                //Check if embed is false
                if(fetched.data.data.attributes.embed == false){
                    this.openInBrowser = true;
                }
                
            }catch(e){
                console.log('e: ', e);
                if(e.response && e.response.data && e.response.data.errors){
                  this.error = e.response.data.errors[0].detail;
                }else{
                  this.error = e;
                }
            }
        },
        windowResize(e){
            this.frameHeightUpdate++;
        }
    },
    setup(){
        const ipaySso = ref("");
        const ipayUrl = ref("");
        const openInBrowser = ref(false)
        const error = ref('');
        const frameHeightUpdate = ref(0)
        const frameHeight = computed({          
            get: () => {
                frameHeightUpdate.value++;      
                    
                if(window.innerWidth >= 768)                 
                    return `height: ${window.innerHeight-225}px;`;      
                else 
                    return `height: ${window.innerHeight-132}px;`;           
            }
        });

        function openUrl(){
              window.open(ipayUrl.value);
        }

        return {
            openUrl,
            error,
            frameHeight,
            frameHeightUpdate,
            ipaySso,
            ipayUrl,
            openInBrowser
        }
    }
}
</script>