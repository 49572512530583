<!-- Chat -->
<template>
    <div :class="`w-full ${mode == 'mapi' ? 'md:w-80' : 'md:w-96'} h-full mn3-content border-r overflow-auto`">
        <div class="text-base uppercase text-center pt-3 pb-4 px-3 font-bold mn3-content-alt border-b flex items-center"> 
            <Icon id="chat-bubble-left-right" class="h-6 w-6 flex-shrink-0" />   
        <p class="pl-2 text-left leading-5">Conversations</p>
        </div>
        <div v-if="showNewConversation" class="flex p-2 mn3-content-hover items-center cursor-pointer border-b"
            @click="this.$emit('startNewConversation')">
            <Icon class="h-6 w-6" id="plus" />
            <p class="ml-2">New Conversation</p>
        </div>
        <div class="w-full flex flex-row justify-around items-center mn3-content py-2">
            <div :class="`${!viewArchived ? 'opacity-100 font-bold' : 'opacity-50'} w-1/2 cursor-pointer uppercase flex-grow text-center flex flex-row justify-center items-center`"
                @click="viewArchived = false; this.$emit('viewOpen');">
                <Icon id="eye" :class="`${viewArchived ? 'opacity-0' : ''} h-4 w-4 mr-2 flex-grow-0`" />
                <span>Live</span>
            </div>
            <div :class="`${viewArchived ? 'opacity-100 font-bold' : 'opacity-50'} w-1/2 cursor-pointer uppercase flex-grow text-center flex flex-row items-center`"
                @click="viewArchived = true; this.$emit('viewClosed');">
                <Icon id="eye" :class="`${!viewArchived ? 'opacity-0' : ''} h-4 w-4 mr-2 flex-grow-0`" />
                <span>Archived</span>
            </div>
        
        </div>
        <div class="py-1">     
            <div class="w-full py-1 px-4 flex flex-row  items-center justify-between mn3-content-alt">
                <label class="flex flex-row items-center flex-grow">
                    <Icon id="search" class="h-5 w-5 mr-2" />
                    <input type="text" class="mn3-content-alt flex-grow" placeholder="Filter" v-model="filter"
                        @keydown.esc="filter = ''" />
                </label>
            </div>
        </div>   
        <div v-if="Object.keys(conversations).length == 0 || filteredConversations.length == 0" class="w-full p-2 text-center italic text-sm">No conversations to display</div>
        <div class="flex flex-col w-full overflow-auto" style="max-height: 70vh; direction:rtl;">
            <div v-for="chat in filteredConversations" :key="chat.name"
                @click="selectConversation(chat);"
                :class="`${conversationClass(chat)} flex p-2 py-4 items-center cursor-pointer`"
                style="direction:ltr">
                <Icon :id="isUnread(chat) ? 'envelope' : 'envelope-open-line'" class="h-5 w-5 mr-1" />
                <div class="flex-col ml-2 w-full">
                    <div class="flex justify-between text-sm font-bold leading-3">
                        <span v-if="mode == 'fapi'">{{chat.attributes.membershipTitle}}</span>
                        <span v-if="chat.attributes.relationshipId != undefined" class="ml-2">({{chat.attributes.relationshipId}})</span>
                    </div>
                    <div class="flex flex-row justify-between items-center">
                        <div class="flex flex-col">
                            <div :class="`leading-4 pt-2 ${isUnread(chat) ? 'font-bold' : ''}`">{{chat.attributes.subject != '' ? chat.attributes.subject : 'Untitled Conversation'}}</div>
                            <div class="text-xs leading-3">{{chat.attributes.modified.split('T')[0]}}</div>
                        </div> 
                        <div v-if="chat.id != showConfirmArchive && mode == 'fapi'" class="pt-2">
                            <Button @click.stop="checkArchive(chat);" icon="archive" text="sm" padding="0" title="Archive Conversation" />
                        </div>
                    </div>
                    <div v-if="chat.id == showConfirmArchive && mode == 'fapi'"
                        class="w-full text-center mt-2 font-bold flex flex-col">
                        <div class="flex flex-row items-center justify-center">
                            <Icon id="archive" class="h-5 w-5 mr-1" />
                            <span>Archive this conversation?</span>
                        </div>
                        <div class="flex flex-row">
                            <Button @click.stop="archiveConversation(chat)" label="Archive" text="sm" class="flex-grow" icon="archive-down" />
                            <Button label="Cancel" text="sm"
                                @click.stop="showConfirmArchive = false"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="moreToLoad" @click="this.$emit('loadMoreConversations')" class="w-full opacity-90 hover:opacity-100 duration-100 text-center mn3-content-alt p-2 leading-5 cursor-pointer">Click to Load Additional Conversations</div>
    </div>
</template>

<script>

    import { ref, computed } from 'vue'

    import Button from '@/components/Button.vue'
    import Icon from '@/components/Icon.vue'

export default{
    props:{  
        activeConversationId: Number,
        showNewConversation:{
            type: Boolean,
            default: true
        },
        conversations:{
            type: Object,
            default: []
        },
        messages: {
            type: Object,
            default: []
        },
        mode: {
            type: String,
            default: 'mapi'
        },
        moreToLoad: {
            type: Boolean,
            default: false
        },
    },
    components:{
        Button,
        Icon
    },
    computed: {
        filteredConversations(){
            if(this.filter == '')
                return this.conversations;

                console.log(this.conversations);
            let f = this.filter.toLowerCase();
            let filtered = [];
            for(var key in this.conversations){

                console.log('convo: ', this.conversations[key]);
                let c = this.conversations[key];

                if(c.attributes.membershipTitle != undefined)
                    if(c.attributes.membershipTitle.toLowerCase().indexOf(f) != -1 ||
                        c.attributes.subject.toLowerCase().indexOf(f) != -1){
                            filtered.push(c);
                        }

                //If membership ID present, check that
                if(c.attributes.relationshipId != undefined){
                    if(c.attributes.relationshipId.toString().toLowerCase().indexOf(f) != -1)
                        filtered.push(c);
                }
            }

            return filtered;

        }
    },
    created(){
        document.title="Conversations";
    },
    mounted(){
    },
    methods:{
        async archiveConversation(chat){
            try{
                let resp = await this.$fapi.patch(chat.links.self, {
                    "data": {
                        "attributes": {
                        "status": "closed", 
                        }
                    }
                });
                this.$emit('refreshConversations');
                this.showConfirmArchive = '';

            }catch(resp){
                console.log('resp catch: ', resp);
            }
        },
        // Display 'Are you sure you want to archive' for chat
        checkArchive(chat){
            this.showConfirmArchive = chat.id;
        },
        conversationClass(chat){
            if(this.showConfirmArchive == chat.id)
                return 'mn3-alert';

            if(this.activeConversationId == chat.id)
                return 'mn3-content-alt';

            if(this.isUnread(chat))
                return 'mn3-content-highlight';

            return 'mn3-content-hover';
        },
        isUnread(chat){
            return (this.mode == 'mapi' && !chat.attributes.memberRead) || (this.mode == 'fapi' && !chat.attributes.fiRead)
        },
        selectConversation(chat){
            //Update read status
            if(this.mode == 'mapi') chat.attributes.memberRead = true;
            else chat.attributes.fiRead = true;

            //Emit to parent to laod convo
            this.$emit('selectConversation', chat.id)
        }
    },
    setup( props, {  } ){

        const filter = ref('')
        const viewArchived = ref(false)
        const showConfirmArchive = ref('');


        return {
            filter,
            showConfirmArchive,
            viewArchived
        }
    }
}

</script>