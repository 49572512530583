<template>
    <div class="w-full max-w-screen-md">
            <div v-if="$store.state.fi.recurringEnabled" 
            @click="$router.push('/settings/transfers')" 
            class="mn3-button mx-1 text-center p-2 rounded-sm cursor-pointer uppercase font-bold flex flex-row items-center mb-1">
                <Icon id="calendar" class="h-6 w-6 mr-2" />
                <p>View Recurring Transfers</p>
            </div>
        <div v-if="transferState == 'error'" class="mn3-content p-3 rounded">
            <div>There was an error while trying to process your transfer, please try again and if the error persists contact support.</div>
            <div class="font-sm font-mono mt-5 p-6">
                <div class="font-bold">Error Details:</div>
                {{error}}
            </div>
        </div>
        <div v-else-if="transferState == 'confirm' || transferState == 'complete' || transferState == 'transferring'"
            class="mn3-content rounded p-4 mb-12">
            <div class="font-bold text-xl">Transfer Details</div>
            <!--Confirm-->                
            <transition name="fade" mode="out-in">
                <div v-if="transferState == 'confirm'">
                    <p>
                        <span>Transferring</span> <span class="font-bold">{{formatMoney(transferDisplayAmount)}}</span> 
                        from <span class="font-bold">{{accounts[transferDisplayFrom].displayAccountId}}</span> 
                        to <span v-if="toDisplayAccountType == 'External'" class="font-bold">{{this.externalAccounts[toDisplayAccountId].label}}</span>  
                            <span v-else class="font-bold">{{toDisplayAccountId}}</span>                        
                    </p>
                    <span v-if="showPrincipalPayment && principalPayment == true" class="mn3-alert px-2 rounded-sm flex flex-row items-center justify-center">
                        <Icon id="exclamation" class="h-6 w-6 mr-2" /><span>Making a Principal Only Payment</span>
                    </span>
                    <div v-if="fv.tranNote.value != ''"
                        class="mn3-content-alt p-1 rounded-sm mb-2">Transfer Note: {{fv.tranNote.value}}</div>
                    <div class="flex w-full justify-center items-center mb-6 mt-5">
                        <div class="border rounded">
                            <div class="border-b p-2 font-bold">{{accounts[transferDisplayFrom].displayAccountId}}</div>
                            <div class="px-4 pt-2 line-through text-center opacity-80">{{formatMoney(transferDisplayAmountBeforeFrom)}}</div>
                            <div class="px-4 font-bold text-xl text-center">{{formatMoney(transferDisplayAmountAfterFrom)}}</div>
                            <div class="px-4 pb-2 text-sm text-center">Balance after Transfer</div>
                        </div>
                        <div class="flex flex-col items-center">
                            <div class="flex flex-col items-center mx-5 h-20 w-20 border rounded-full p-2">
                                <Icon id="arrow-right" class="h-10 w-10" />
                                <div class="borber rounded font-bold text-sm">{{formatMoney(transferDisplayAmount)}}</div>
                            </div>
                        </div>
                        <div v-if="toDisplayAccountType == 'LineOfCredit'|| toDisplayAccountType == 'CreditCard'"
                            class="border rouned">                            
                            <div class="border-b p-2 font-bold">{{toDisplayAccountId}}</div>
                            <div class="px-4 pt-2 line-through text-xl text-center opacity-80">{{formatMoney(transferDisplayAmountBeforeTo)}}</div>
                            <div class="px-4 pb-2 text-sm text-center font-bold">Payment of {{formatMoney(transferDisplayAmount)}}</div>
                        </div>
                        <div v-else-if="toDisplayAccountType == 'CC'"
                            class="border rouned">                            
                            <div class="border-b p-2 font-bold">Credit Card Payment</div>
                            <div class="px-4 pb-2 text-sm text-center font-bold mt-2">Payment will be made on your behalf</div>
                        </div>
                        <div v-else-if="toDisplayAccountType == 'Check'"
                            class="border rouned">                            
                            <div class="border-b p-2 font-bold">{{toDisplayAccountId}}</div>
                            <div class="px-4 pb-2 text-sm text-center font-bold mt-2">Check Will Be Sent to Address On File</div>
                        </div>
                        <div v-else-if="toDisplayAccountType == 'External'"
                            class="border rouned">                            
                            <div class="border-b p-2 font-bold w-64">{{this.externalAccounts[toDisplayAccountId].label}}</div>
                            <div class="px-4 pb-2 text-sm text-center font-bold mt-2">Transfering to an external account</div>
                        </div>
                        <div v-else
                            class="border rounded">
                            <div class="border-b p-2 font-bold">{{toDisplayAccountId}}</div>
                            <div class="px-4 pt-2 line-through text-center opacity-80">{{formatMoney(transferDisplayAmountBeforeTo)}}</div>
                            <div class="px-4 font-bold text-xl text-center">{{formatMoney(transferDisplayAmountAfterTo)}}</div>
                            <div class="px-4 pb-2 text-sm text-center">Balance after Transfer</div>
                        </div>
                    </div>
                    <!-- CC Notice -->
                    <div v-if="ccPaymentConfirmMessage && toDisplayAccountType == 'CreditCard'"
                        class="mn3-content border mx-auto my-5 rounded-sm w-5/6 px-4 cc-notice" v-html="ccPaymentConfirmMessage" />
                    <div class="flex w-full p-2">
                        <Button 
                            class="flex-grow"
                            size="large"
                            type="submit"
                            @click="transfer"
                            label="Submit Transfer" />
                        <Button 
                            class="ml-1"
                            size="large"
                            type="cancel"
                            @click="transferState = 'default'"
                            label="Cancel" />
                    </div>
                </div>
            </transition>
            <!--Complete-->       
            <transition name="fade" mode="out-in">
                <div v-if="transferState == 'complete'">
                    <div class="mx-auto uppercase p-5 rounded mn3-notice border my-5 w-3/4 text-center">
                        <p class="text-3xl font-bold">Transfer Complete</p>
                        <div>                            
                            <span class="mr-1">Transferred</span><span class="font-bold">{{formatMoney(transferDisplayAmount)}}</span> 
                            from <span class="font-bold">{{accounts[transferDisplayFrom].displayAccountId}}</span> 
                            to <span v-if="toDisplayAccountType == 'External'" class="font-bold">{{this.externalAccounts[toDisplayAccountId].label}}</span>  
                            <span v-else class="font-bold">{{toDisplayAccountId}}</span>       
                        </div>
                    </div>
                    <div class="flex w-full justify-center items-center mb-6">
                        <div class="border rounded">
                            <div class="border-b p-2 font-bold">{{accounts[transferDisplayFrom].displayAccountId}}</div>
                            <div class="px-4 pt-2 line-through text-center opacity-80">{{formatMoney(transferDisplayAmountBeforeFrom)}}</div>
                            <div class="px-4 font-bold text-xl text-center">{{formatMoney(transferDisplayAmountAfterFrom)}}</div>
                            <div class="px-4 pb-2 text-sm text-center">Balance after Transfer</div>
                        </div>
                        <div class="mx-5 h-16 w-16 border rounded-full p-2">
                            <Icon id="arrow-right" />
                        </div>
                        <div v-if="toDisplayAccountType == 'LineOfCredit' || toDisplayAccountType == 'CreditCard'"
                            class="border rouned">                            
                            <div class="border-b p-2 font-bold">{{toDisplayAccountId}}</div>
                            <div class="px-4 pt-2 text-xl line-through text-center opacity-80">{{formatMoney(transferDisplayAmountBeforeTo)}}</div>
                            <div class="px-4 pb-2 text-sm text-center font-bold">Payment of {{formatMoney(transferDisplayAmount)}}</div>
                        </div>
                        <div v-else-if="toDisplayAccountType == 'CC'"
                            class="border rouned">                            
                            <div class="border-b p-2 font-bold">Credit Card Payment</div>
                            <div class="px-4 pb-2 text-sm text-center font-bold mt-2">Payment will be made on your behalf</div>
                        </div>
                        <div v-else-if="toDisplayAccountType == 'Check'"
                            class="border rouned">                            
                            <div class="border-b p-2 font-bold">{{toDisplayAccountId}}</div>
                            <div class="px-4 pb-2 text-sm text-center font-bold mt-1">Check Will Be Sent to Address On File</div>
                        </div>
                        <div v-else-if="toDisplayAccountType == 'External'"
                            class="border rouned">                            
                            <div class="border-b p-2 font-bold w-64">{{this.externalAccounts[toDisplayAccountId].label}}</div>
                            <div class="px-4 pb-2 text-sm text-center font-bold mt-2">Transfered to an external account</div>
                        </div>
                        <div v-else class="border rounded">
                            <div class="border-b p-2 font-bold">{{toDisplayAccountId}}</div>
                            <div class="px-4 pt-2 line-through text-center opacity-80">{{formatMoney(transferDisplayAmountBeforeTo)}}</div>
                            <div class="px-4 font-bold text-xl text-center">{{formatMoney(transferDisplayAmountAfterTo)}}</div>
                            <div class="px-4 pb-2 text-sm text-center">Balance after Transfer</div>
                        </div>
                    </div>
                    <div class="flex justify-around">
                        <div class="mn3-button border text-center cursor-pointer p-2 rounded w-1/3" @click="transferState = 'default'">New Transfer</div>
                        <div class="mn3-button border text-center cursor-pointer p-2 rounded w-1/3" @click="$router.push('/accounts')">View Accounts</div>
                    </div>
                </div>
            </transition>
        </div>
        <transition name="fade" mode="out-in">
            <Form v-if="transferState == 'default'"
                title="Transfer Funds"
                submitText="Transfer Funds"
                class="w-full"
                :error="error"
                @submit="goToConfirm"
                :showCancel="false">
                <Field label="From Account"
                    type="select"
                    :options="optionsFrom"
                    :formValue="fv.fromAccountId" />
                <Field label="To Account"
                    type="select"
                    :options="optionsTo"
                    :formValue="fv.toAccountId" />
                <label v-if="showPrincipalPayment"
                    class="flex flex-row items-center ml-5">
                    <input v-model="principalPayment" ref="refPrincipalPayment" type="checkbox" class="w-6 h-6" />
                    <span class="ml-2 text-sm">Principal Only Payment</span>
                </label>                 
                <Field v-if="fv.toAccountId.value == 'CC'" 
                    label="Last 4 Digits of Credit Card"  
                    class="text-lg"             
                    type="number"
                    maxLength="4"
                    align="left"
                    :formValue="fv.last4" />
                <div class="flex flex-row" v-if="fv.toAccountId.value == '-2'" >
                    <Field 
                        class="flex-grow"
                        type="select"
                        :formValue="fv.addressTo"
                        label="Address Book Account"
                        :options="[
                            { label: '', value: '' },
                            { label: 'Person McPerson (Acct# 24) RTN 135879614', value: '0' },
                            { label: 'George Georgie (Acct# 0015487) RTN 00001234567', value: '1' },
                            { label: 'Person McPerson SH (Acct# 124) RTN 135879614', value: '2' }
                        ]" />
                    <div class="flex flex-col justify-center">
                        <Button
                            @click="stateSave();this.$router.push('/settings?sub_menu=addresses&return=transfer')"
                            icon="book-open" 
                            class="mx-2 mt-1" 
                            label="Edit Saved Addresses" />
                    </div>
                </div>      
                <Field v-if="$store.state.fi.system == 'encompass' && fv.toAccountId.value != 'CC'" 
                    label="Transfer Note (Optional)"  
                    class="text-lg"             
                    type="text"
                    maxLength="28"
                    align="left"
                    :formValue="fv.tranNote" />             
                <div class="flex flex-row">
                    <Field 
                        :label="`${fv.toAccountId.value == 'CC' ? 'Payment Amount' : 'Transfer Amount'}`"  
                        class="text-lg flex-grow"             
                        type="text"
                        textFormat="amount"
                        align="left"
                        :formValue="fv.tranAmount" />
                    <div class="flex flex-col justify-center">
                        <Button v-if="showFullPayoff" 
                            @click="setToFullPayoff()" 
                            icon="trend-down" 
                            class="mx-2 mt-1" 
                            label="Payoff Full Amount" />
                    </div>
                </div>
            </Form>
        </transition>
    </div>
</template>
<script>
    import { ref } from 'vue'
    
    import Button from '@/components/Button.vue'
    import Form from '@/components/Form.vue'
    import Field from '@/components/Field.vue'
    import Icon from '@/components/Icon.vue'
    
    export default {
        name: 'Transfer',
        components: {
            Button,
            Form,
            Field,
            Icon
        },
        created(){
                document.title="Transfer";
                
            if(this.$route.query.restore == '1'){
                this.stateLoad()
            }
        },
        computed:{
            addressTestMode(){
                return this.$store.state.fi.id == 2;
            },
            /* Dropdown options for the from account */
            optionsFrom(){                
                let options = [];
                for(var ii = 0; ii < this.fromAccountIds.length; ii++){
                    let key = this.fromAccountIds[ii]
                    let acct = this.accounts[key]     
                    let group = acct.ownerName

                    let bal = '';
                    if(acct.availableBalance){
                        bal = ` • Avail Bal: ${this.formatMoney(acct.availableBalance)}`;
                        if(acct.type == 'CreditCard' || acct.type == 'Loan' || acct.type == 'LineOfCredit' || acct.type == 'Mortgage'){
                            bal = ` • Current Bal: ${this.formatMoney(acct.actualBalance)}`;
                        }
                    }

                    options.push({
                        label: `${acct.type}: ${acct.displayAccountId} • ${(acct.nickname != undefined) ? acct.nickname : acct.description}${bal}`,
                        value: key,
                        group: group
                    })
                }	
               
                return options;
            },
            /* Dropdown options for the to account */
            optionsTo(){
                let options = [];
                let toMap = this.toAccountIdMap[this.fv['fromAccountId'].value]        
                if(toMap !== undefined){        
                    for(var ii = 0; ii < toMap.length; ii++){        
                        let key = toMap[ii]
                        let acct = this.accounts[key]    
                        let group = acct.ownerName
                        let desc = 'Avail Bal';
                        let amt = acct.availableBalance;

                        
                        if(acct.type == 'CreditCard' || acct.type == 'Loan' || acct.type == 'LineOfCredit' || acct.type == 'Mortgage'){
                            desc = 'Current Bal';
                            amt =  acct.actualBalance;
                        }

                        let label = `${acct.type}: ${acct.displayAccountId} • ${(acct.nickname != undefined) ? acct.nickname : acct.description} ${amt ? ' • ' + desc + ': ' + this.formatMoney(amt) : ''}`;
                        if(acct.payoffBalance && acct.payoffBalance > 0)
                            label += ` • Payoff: ${this.formatMoney(acct.payoffBalance)}`;

                        // Check for type specific labels
                        if(acct.type == 'Check'){
                            label = 'Mail check to address on file';
                        }                        
                        else if(acct.type == 'CC'){
                            label = 'Credit Card Payment';
                        }
                        
                        if(acct.type.toLowerCase() == 'loan'){
                            amt = acct.nextPaymentAmount
                            desc = 'Next Payment'
                        }

                        options.push({
                            label: label,
                            value: key,
                            group: group
                        });
                    }
                }else{
                    options.push({
                        label: 'From Account must be selected first',
                        value: -1
                    })
                }  
                
                if(this.addressTestMode)
                    options.push({label: 'Account From Address Book', value: '-2'});

                return options;
            },
            showFullPayoff(){
                let toAccount = this.accounts[this.fv['toAccountId'].value];
                if(toAccount == undefined)
                    return false;

                return (toAccount.payoffBalance > 0);

            },
            showPrincipalPayment(){
                if(this.$store.state.fi.system != 'encompass' || !this.$store.state.fi.principalOnlyPaymentsEnabled)
                    return false;

                let toAccount = this.accounts[this.fv['toAccountId'].value];
                if(toAccount == undefined)
                    return false;

                if(toAccount.type == 'Loan' || toAccount.type == 'LineOfCredit' || toAccount.type == 'Mortgage')                
                    return true;

                return false;
            },
            toDisplayAccountType(){
                if(this.addressTestMode && this.fv.addressTo.value != ''){
                    return 'External'
                }else{
                    return this.accounts[this.transferDisplayTo].type
                }
            },
            toDisplayAccountId(){
                if(this.addressTestMode && this.fv.addressTo.value != ''){
                    return this.fv.addressTo.value;
                }else{
                    return this.accounts[this.transferDisplayTo].displayAccountId
                }
            }
        },
        methods: {
            stateLoad(){
                let state = localStorage.getItem('transfer-state');
                console.log('stateLoad: ', JSON.parse(state))
                if(state){
                    state = JSON.parse(state)
                    if(state){
                    this.fv.fromAccountId.value = state.fromAccount
                    this.fv.toAccountId.value = state.toAccount
                    this.fv.tranNote.value = state.note
                    this.fv.tranAmount.value = state.amount
                    this.principalOnly = state.principalOnly
                    }
                }
                localStorage.setItem('transfer-state', null);
            },
            stateSave(){
                let state = {
                    fromAccount: this.fv.fromAccountId.value,
                    fromAddressBook: '',
                    toAccount: this.fv.toAccountId.value,
                    toAddressBook: '',
                    note: this.fv.tranNote.value,
                    amount: this.fv.tranAmount.value,
                    principalOnly: this.principalPayment
                }
                localStorage.setItem('transfer-state', JSON.stringify(state));
            },
            /* Format a value for display */
            formatMoney(val){
                if(isNaN(parseFloat(val)))
                    return '';
                return this.mon.format(val);
            },
            principalPaymentIsChecked(){
                if(this.$refs.refPrincipalPayment)
                    return this.$refs.refPrincipalPayment.checked;

                return false;
            },
            /* Validate input and go to transfer confirmation screen */
            goToConfirm(){
                this.transferDisplayAmount  = this.fv['tranAmount'].value
                this.transferDisplayFrom =  this.fv['fromAccountId'].value
                this.transferDisplayTo =  this.fv['toAccountId'].value
        
                let success = true;
                let issue = '';
                let amt = parseFloat(this.transferDisplayAmount)

                //If principal payment, make sure payment amount isn't greater than current balance            
                if(this.showPrincipalPayment && this.principalPayment && this.principalPaymentIsChecked() && this.transferDisplayAmount > this.accounts[this.transferDisplayTo].actualBalance){
                    success = false;
                    issue = 'Principal Only amount exceeds the current balance of the loan';
                }
                else if(this.accounts[this.transferDisplayFrom] === undefined){
                    success = false;
                    issue = 'Please select an account to transfer from'
                }
                else if(this.accounts[this.transferDisplayTo] === undefined && 
                (this.fv.addressTo == '')){
                    success = false;
                    issue = 'Please select an account to transfer to'
                }
                else if(this.transferDisplayTo == 'CC' && this.fv.last4.value.length < 4){
                    success = false;
                    issue = 'Please enter last 4 digits of CC'
                }
                else if(parseFloat(this.accounts[this.transferDisplayFrom].availableBalance) < amt){
                    success = false;
                    issue = 'Transfer amount exceeds the available balance of the account'
                }
                else if(this.transferDisplayFrom == this.transferDisplayTo){
                    success = false;
                    issue = 'You cannot transfer funds to the same account'
                }else if(amt <= 0 || isNaN(amt)){
                    success = false;
                    issue = 'There was an error with the amount'
                }
        
                if(this.accounts[this.transferDisplayFrom] != undefined){
                    this.transferDisplayAmountBeforeFrom = parseFloat(this.accounts[this.transferDisplayFrom].availableBalance)
                    this.transferDisplayAmountAfterFrom = parseFloat(this.accounts[this.transferDisplayFrom].availableBalance) - amt
                }
                
                if(this.accounts[this.transferDisplayTo] != undefined){
                    // Determine which value to use for before display
                    if(this.toDisplayAccountType == 'CreditCard' || this.toDisplayAccountType == 'Loan' || this.toDisplayAccountType == 'LineOfCredit' || this.toDisplayAccountType == 'Mortgage')
                        this.transferDisplayAmountBeforeTo = parseFloat(this.accounts[this.transferDisplayTo].actualBalance)
                    else
                        this.transferDisplayAmountBeforeTo = parseFloat(this.accounts[this.transferDisplayTo].availableBalance)

                    if(this.toDisplayAccountType == 'LineOfCredit' || this.toDisplayAccountType == 'Loan' || this.toDisplayAccountType == 'Mortgage'){
                        this.transferDisplayAmountAfterTo = parseFloat(this.accounts[this.transferDisplayTo].availableBalance) - amt
                    }else{
                        this.transferDisplayAmountAfterTo = parseFloat(this.accounts[this.transferDisplayTo].availableBalance) + amt
                    }
                }        
        
                if(success){
                    this.transferState = "confirm";
                    this.error = "";
                }else{  
                    this.error = issue
                }
            },
            // Set payment amount to full payoff value
            setToFullPayoff(){
                this.fv['tranAmount'].value = this.accounts[this.fv['toAccountId'].value].payoffBalance.toString();
            },
            /* Post transfer and go to results screen */
            async transfer(){

                //UI TEST: Check if testing external account transfer
                if(this.toDisplayAccountType == 'External'){
                    this.transferState = 'complete';
                    this.fv['toAccountId'].value = ''                    
                    this.fv['tranAmount'].value = '0.00' 
                    this.fv['last4'].value = ''
                    this.fv["tranNote"].value = ""
                    return
                }

                //Make sure not already transferring
                if(this.transferState == 'transferring')
                    return;
    
                this.transferState = "transferring"
    
                //Make sure the value in amount has 2 decimals in the input, as doing a toFixed(2)
                // causes a 'Object is of wrong type' response
                this.fv['tranAmount'].value = parseFloat(this.fv['tranAmount'].value).toFixed(2)    
                let amt = parseFloat(this.fv['tranAmount'].value)
                if (isNaN(amt)){
                    amt = ''
                    this.fv['tranAmount'].value = '0.00'
                }    
                if(amt.toString().indexOf('.') === -1)
                    amt += .00
    
                let transData = {
                    "data": {
                        "attributes": {
                            "fromAccountId": this.fv['fromAccountId'].value,
                            "toAccountId": this.fv['toAccountId'].value,
                            "amount": amt
                        }
                    }
                };

                if(this.showPrincipalPayment && this.principalPayment == true){
                    transData['data']['attributes']['principalOnly'] = true;
                }

                // Set CC payment last 4 digits
                if(this.fv['toAccountId'].value == 'CC'){
                    transData['data']['attributes']['memo'] = this.fv['last4'].value.toString();
                }
                // Set transfer note
                else if(this.fv['tranNote'].value != '' && this.$store.state.fi.system == 'encompass'){
                    transData['data']['attributes']['memo'] = this.fv['tranNote'].value.toString();
                }
    
                this.$mapi.post('transfer', transData).then(r=>{
                    this.error = ''        
                    
                    this.successAmount = this.fv['tranAmount'].value
                    this.successFrom = this.fv['fromAccountId'].value
                    this.successTo = this.fv['toAccountId'].value                    
    
                    this.fv['toAccountId'].value = ''                    
                    this.fv['tranAmount'].value = '0.00' 
                    this.fv['last4'].value = ''
                    this.fv["tranNote"].value = ""

                    this.transferState = "complete"
    
                    //Get updated account totals
                    let opt = this.$mapi.get('transfer-options').then(r=>{
                        let atr = r.data.data.attributes
                        this.accounts = atr.accounts
                        this.fromAccountIds = atr.fromAccountIds
                        this.toAccountIdMap = atr.toAccountIdMap
                    })
                })
                .catch((ex) => {  
                    console.log('---TRANSFER ERROR---')    
                    console.log('Transfer Data: ', transData)          
                    console.log(ex.response.data)                
                    console.log(ex.response.data.errors[0])
                    this.error = ex.response.data.errors[0].title + ': ' + ex.response.data.errors[0].detail
    
                    this.transferState = "error"
                });    
            }
        },
        async mounted(){
            // Load transfer options
            try{
                const r = await this.$mapi.get('transfer-options');
                let atr = r.data.data.attributes
                console.log('fetch: ', atr);
                this.accounts = atr.accounts
                this.fromAccountIds = atr.fromAccountIds
                this.toAccountIdMap = atr.toAccountIdMap
                this.ccPaymentConfirmMessage = atr.ccPaymentConfirmMessage
            }catch(ex){
                console.log("Reload Error Catch: ", ex);   
                //Force inactive logout
                this.$store.commit('setShowInactiveNotice', true);           
                this.$authapi.logout();
            }
        },
        setup(){        
               /* --Variables-- */
               let accounts = ref([])                       //accounts: Information about accounts that can be transfered from/to
               let error = ref("")                          //error: Error with transfer
               let fromAccountIds = ref([])                 //fromAccountIds: Accounts that can be transfered from
               let fv = {}                                  //fv: Array of refs used to store input data
                fv["fromAccountId"] = ref("")               //fv.fromAccountId: Account transfering from
                fv["toAccountId"] = ref("-1")               //fv.toAccountId: Account transfering to
                fv["tranAmount"] = ref("")                  //fv.tranAmount: Amount to transfer
                fv["tranNote"] = ref("");
                fv["last4"] = ref("");
                fv["addressFrom"] = ref("");
                fv["addressTo"] = ref("");

               let mon                                      //mon: Used to format money
                   = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
               let successAmount = ref()                    //successAmount: Amount of succesful transfer
               let successFrom = ref()                      //successFrom: Account transfer was from
               let successTo = ref()                        //successTo: Account transfer was to
               let toAccountIdMap = ref({})                 //toAccountIdMap: Map of accounts for transfering
               let transferDisplayAmount = ref()            //transferDisplayAmount: Amount of transfer, displayed on confirm screen
               let transferDisplayFrom = ref()              //transferDisplayFrom: Account from, displayed on confirm screen
               let transferDisplayTo = ref()                //transferDisplayTo: Account to, displayed on confirm screen
               let transferDisplayAmountAfterFrom = ref()   //transferDisplayAmountAfterFrom: From after balance, displayed on confirm screen
               let transferDisplayAmountAfterTo = ref()     //transferDisplayAmountAfterTo: To after balance, displayed on confirm screen
               let transferDisplayAmountBeforeFrom = ref()  //transferDisplayAmountBeforeFrom: From before balance, displayed on confirm screen
               let transferDisplayAmountBeforeTo = ref()    //transferDisplayAmountBeforeTo: To before balance, displayed on confirm screen
               let transferState = ref('default')           //transferState: Current state screen is in
               let principalPayment = ref('false');
               let ccPaymentConfirmMessage = ref('')

               const externalAccounts = ref({
                    0: { label: 'Person McPerson (Acct# 24) RTN 135879614', value: '0' },
                    1: { label: 'George Georgie (Acct# 0015487) RTN 00001234567', value: '1' },
                    2: { label: 'Person McPerson SH (Acct# 124) RTN 135879614', value: '2' }
                });

            return{
                accounts,
                ccPaymentConfirmMessage,
                externalAccounts,
                transferDisplayAmountAfterFrom,
                transferDisplayAmountAfterTo,   
                transferDisplayAmountBeforeFrom,
                transferDisplayAmountBeforeTo,  
                fromAccountIds,
                fv,
                mon,
                principalPayment,
                transferState,
                successAmount,
                successFrom,
                successTo,
                transferDisplayAmount,
                transferDisplayFrom,
                transferDisplayTo,
                toAccountIdMap,
                error
            }    
        }
    }
</script>

<style>
.cc-notice p{
    margin-top: 15px;
    margin-bottom: 15px;
}
</style>